<template>
  <CommonPage id="home" title="TimeCheckNow" classs="home" :isFullScreen="isFullScreen">
    <section class="home-content pc_padding p-t-60">
      <div class="time-page-card-container">
        <div class="time-page-card-row">
          <div v-for="(data,i) in card" :key="i" class="time-page-card-item">
            <div class="time-page-card-image">
              <img :src="data.img" :alt="data.title">
            </div>
            <div class="time-page-card-content">
              <div class="time-page-card-title">
                {{ data.title }}
              </div>
              <div class="time-page-card-desc">
                {{ data.desc }}
              </div>
            </div>
            <div class="time-page-card-btn" @click="goto(data.routename)">
              Read more
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/card-1.png'),
          title: '9 Time Management Tips to Benefit Your Life',
          desc: 'There is a term called the "busyness trap."It means that in modern society, everyone is constantly on the go, busy but not knowing how to break free from it.',
          routename: 'blog1'
        },
        {
          img: require('@/assets/card-2.png'),
          title: 'The Ultimate Guide to Overcoming Jet Lag',
          desc: 'The pain of overcoming jet lag is something you, who are looking at your phone right now, have surely expe rienced. Today, Alice wants to chat with everyone...',
          routename: 'blog2'
        },
        {
          img: require('@/assets/card-3.png'),
          title: 'How to Properly Plan Your Day?',
          desc: 'In our fast-paced lives, time is like gold, with every second being incredibly precious. We all have 24 hours, but why do some people manage to accomplish...',
          routename: 'blog3'
        },
        {
          img: require('@/assets/card-4.png'),
          title: 'How to Create a Daily Self-Discipline Plan on a Single Sheet of Paper: 7 Dai...',
          desc: 'The 24 hours in a day will pass regardless, but the difference between people lies in their attitude towards time...',
          routename: 'blog4'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (name) {
      this.$router.push({ name: name });
    }
  },
}
</script>
